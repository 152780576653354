@import 'variables';

.mat-progress-bar {
  height: 8px !important;
  border-radius: 3px;

  .mat-progress-bar-fill {
    border-radius: 3px;
  }

  .mat-progress-bar-fill::after {
    border-radius: 3px 0 0 3px;
    background: linear-gradient(270deg, rgb(78 176 141 / 20%) -4.22%, rgb(78 176 141 / 2.8%) 104.79%),
      linear-gradient(273.43deg, rgb(78 176 141 / 88%) 1.79%, rgb(78 176 141 / 25%) 26.01%, rgb(78 176 141 / 0%) 111.2%),
      linear-gradient(270deg, rgb(78 176 141 / 60%) 1.37%, rgb(78 176 141 / 13%) 102%);
    border-right: 2px solid $tpp-white;
  }

  &.warn .mat-progress-bar-fill::after {
    background: none;
    background-color: $tpp-input-border-warning;
  }

  &.error .mat-progress-bar-fill::after {
    background: none;
    background-color: $tpp-input-border-error;
  }

  .mat-progress-bar-buffer {
    background-color: $tpp-gray2;
  }
}
