@import './fonts.mixins';
@import 'variables';

@mixin buttonItem($size: 28px) {
  .button-item {
    font-size: 24px;
    width: $size;
    height: $size;
    justify-content: center;
    align-items: center;
    display: flex;
    box-sizing: border-box;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    background-size: calc($size * 3 / 4);
    box-shadow: 0 2px 6px rgb(72 72 72 / 2%);

    &:disabled {
      opacity: 0.2;
    }

    &:hover {
      background-color: $tpp-button-hover-default;
    }

    ::ng-deep {
      .mat-button-wrapper {
        display: flex;
      }
    }
  }

  app-turnover-filter-menu.button-item::ng-deep {
    button.turnover-filter-trigger {
      width: auto;
      height: $size;

      app-icon mat-icon {
        height: calc($size * 0.6) !important;
        width: calc($size * 0.6) !important;
      }
    }
  }

  app-collapsible-search-input.button-item::ng-deep {
    width: fit-content;
    min-width: $size;

    .search-input-container {
      height: $size;
      max-width: 200px;

      input {
        height: $size;
        border-radius: 4px;
      }

      &.collapsed {
        max-width: $size;
      }
    }
  }
}

@mixin buttonGroupContainer($size: 28px) {
  display: flex;
  background-color: $tpp-gray2;
  border-radius: 5px;
  gap: 3px;
  padding: 2px 3px;

  @include buttonItem;
}

button {
  @include systemFont;

  height: 40px;
  font-size: 16px;

  .mat-button-wrapper {
    letter-spacing: 0.05em;
  }

  &.dropdown-control-button {
    height: 32px;
    line-height: 30px;
  }

  &.edit {
    width: 45px;
  }

  &.save {
    width: 64px;
  }

  &.delete {
    width: 64px;
  }

  &.turnover-save {
    width: 83px;
  }

  &.turnover-view-history {
    width: 179px;
  }

  &.join {
    font-size: 14px;
    color: $tpp-gray15;
    margin-top: -3px;

    mat-icon {
      transform: rotate(90deg);
    }
  }

  &.new-turnover {
    background-color: $tpp-green2;
    width: 40px;
  }

  &.modal-button {
    height: 28px;
    padding: 0 24px !important;
    line-height: 16px;
  }

  &.step-button {
    height: 38px;
    padding: 0 24px !important;
  }

  &.comment-button {
    height: 20px;
    font-size: 11px !important;
    line-height: 20px;
  }

  &.turnovers-header-button {
    height: 33px;
    line-height: 33px;

    &.only-mine {
      width: 100px;
      height: 32px;
      padding: 5px 8px;
    }

    &.join {
      font-size: 14px;
    }

    &.toggle-all-sections {
      width: 16px;
      height: 16px;
      padding: 0;
      border: 1px solid $tpp-gray2;
      border-radius: 3px;
    }

    &.unit-section {
      .mat-button-wrapper {
        font-size: 24px;
      }
    }
  }

  mat-icon {
    &.remove-icon {
      color: $tpp-failure;
    }

    &.add-icon-large {
      color: map-get($map: $mat-myapp-primary, $key: 700);
      font-size: 2em;
    }
  }

  &.pp-button {
    font-weight: 700;
    padding-left: 27px;
    padding-right: 27px;
    width: 185px;
    height: 40px;

    &.role {
      width: 117px;
    }
  }

  &.back-to-parent {
    width: 119px;
    height: 40px;
  }

  &.mat-icon-button {
    .mat-button-ripple.mat-ripple,
    .mat-button-focus-overlay {
      inset: -2px;
    }

    &.btn-attachment {
      width: 24px;
      height: 24px;
    }

    &.button-icon-background {
      width: 22px;
      height: 22px;
      background-color: $tpp-gray10;
    }

    &.button-icon-delete {
      width: 24px;
      height: 24px;
      background-color: $tpp-gray10;
      border-radius: 4px;
    }

    &.button-icon-toggle {
      width: 30px;
      height: 30px;
    }
  }
}

button.mat-stroked-button {
  background-color: $tpp-white;
}

button.mat-stroked-button:not(.mat-button-disabled) {
  border-color: $tpp-gray11;
}

button.mat-button,
button.mat-stroked-button,
button.mat-flat-button {
  min-width: 1%;
  padding: 0 8px;

  // TODO
  // padding: 8px 24px;

  @include systemFont;

  font-weight: 700;
  border-radius: 5px;

  & mat-icon {
    margin-right: 3px;
  }

  &.thin-button {
    line-height: 30px !important;
  }

  &.extra-thin-button {
    line-height: 22px !important;
  }

  &.modal-close {
    width: 34px;
    height: 34px;
    padding: 0;
  }
}

button.mat-button-base.button-smaller {
  padding: 5px 8px;
  line-height: 125%;
  font-size: 16px;
  height: auto;
}

button,
a {
  &.mat-stroked-button {
    &.mat-primary {
      border-color: $tpp-green2;
      color: $tpp-green2;
    }

    &.mat-error {
      border-color: $tpp-input-border-error;
      color: $tpp-input-border-error;
    }

    &.mat-button-disabled {
      border-color: $tpp-inner-border-default;
    }

    &.reject {
      line-height: 28px;
      height: 32px;
      padding: 0 8px;
      margin: 0;
    }
  }

  &.mat-flat-button {
    &.mat-primary {
      background: $tpp-green2;
    }
  }

  &.mat-raised-button {
    &.mat-primary {
      background: $tpp-green2;
    }

    &.accept {
      line-height: 32px;
      height: 32px;
      padding: 0 8px;
      margin: 0;
    }
  }

  &.mat-fab {
    &.mat-primary {
      background: $tpp-green2;
    }

    &.new-turnover-button,
    &.floating-button {
      height: 60px;
      width: 60px;
      background-color: $tpp-white;
      border: 2px solid $tpp-input-border-focus;
      padding: 0;

      .mat-button-wrapper {
        padding: 0;
      }

      .content {
        height: 56px;
        width: 56px;
      }
    }
  }

  &.mat-button.mat-primary {
    color: $tpp-green2;

    &.mat-button-disabled {
      color: $tpp-gray7;
      cursor: not-allowed;
    }

    &.table-link {
      font-weight: 500;
      padding: 0;
    }
  }

  &.mat-primary-light {
    background: $tpp-primary-light;
    color: $tpp-white;
  }
}

.mat-warn {
  &:not(.mat-button-disabled) {
    &.mat-stroked-button {
      color: $tpp-red4;
      border: 1px solid $tpp-red4;
    }

    &.mat-raised-button {
      background-color: $tpp-red4;
    }
  }
}

.modal-button {
  height: 28px;
  line-height: 28px;
}

.mat-mini-fab {
  &.sidebar-toggle {
    width: 24px;
    height: 24px;
    background-color: $tpp-white !important;
    line-height: 0;

    .mat-button-wrapper {
      padding: 0 !important;
    }
  }
}

button.button-icon {
  height: 30px;
  width: 30px;
  transition: all ease 200ms;

  mat-icon {
    color: #000000de;
    height: 30px;
    width: 30px;

    svg path {
      fill: #000000de;
    }
  }
}
