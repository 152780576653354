@import 'variables';

.mat-tab-group {
  .mat-ink-bar {
    background-color: $tpp-green2 !important;
  }

  .mat-tab-labels {
    .mat-tab-label {
      opacity: 1;
      color: $tpp-gray3;

      &.mat-tab-label-active {
        color: $tpp-green2;
      }
    }
  }
}
