@use '@angular/material' as mat;
@import 'fonts.mixins';
@import 'variables';
@import 'tables.mixins';

table.mat-table {
  border-spacing: 0;

  @include systemFont;

  background: $tpp-white;

  &.header-padding {
    padding-top: 8px;
  }

  .mat-header-cell {
    font-size: 14px;
    font-weight: 700;

    @include tableCells;
  }

  tbody {
    tr:nth-child(odd) {
      background: $tpp-gray6;
    }

    tr {
      &.odd-row {
        background: $tpp-gray6;
      }

      &.even_row {
        background: $tpp-white;
      }
    }

    tr.no-data-row {
      text-align: center;
    }
  }

  &.row-separate {
    border-spacing: 0 20px;

    tbody {
      tr {
        background: $tpp-white;
      }
    }
  }
}

::ng-deep .mat-sort-header-arrow[style] {
  .mat-sort-header-stem {
    display: none;
  }

  .mat-sort-header-indicator {
    display: none;

    .mat-sort-header-pointer-left,
    .mat-sort-header-pointer-right,
    .mat-sort-header-pointer-middle {
      display: none;
    }
  }
}

[aria-sort='ascending'] {
  ::ng-deep .mat-sort-header-arrow {
    &::before {
      content: url("data:image/svg+xml,%3Csvg width='6' height='13' viewBox='0 0 6 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 0L5.59808 4.5H0.401924L3 0Z' fill='%23212121'/%3E%3Cpath d='M3 13L0.401924 8.5H5.59808L3 13Z' fill='%23B9B8B8'/%3E%3C/svg%3E%0A");
      position: absolute;
      bottom: 0;
    }
  }
}

[aria-sort='descending'] {
  ::ng-deep .mat-sort-header-arrow {
    &::before {
      content: url("data:image/svg+xml,%3Csvg width='6' height='13' viewBox='0 0 6 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 0L5.59808 4.5H0.401924L3 0Z' fill='%23B9B8B8'/%3E%3Cpath d='M3 13L0.401924 8.5H5.59808L3 13Z' fill='%23212121'/%3E%3C/svg%3E%0A");
      position: absolute;
      bottom: 0;
    }
  }
}

[aria-sort='none'] {
  ::ng-deep .mat-sort-header-arrow {
    &::before {
      content: url("data:image/svg+xml,%3Csvg width='6' height='13' viewBox='0 0 6 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 0L5.59808 4.5H0.401924L3 0Z' fill='%23B9B8B8'/%3E%3Cpath d='M3 13L0.401924 8.5H5.59808L3 13Z' fill='%23B9B8B8'/%3E%3C/svg%3E");
      position: absolute;
      bottom: 0;
    }
  }
}

.priority-high {
  background: $tpp-red4 !important;
}

.priority-high-color {
  color: $tpp-red4 !important;
}

.priority-medium {
  background: $tpp-orange !important;
}

.priority-medium-color {
  color: $tpp-orange !important;
}

.priority-low {
  background: $tpp-inner-border-default !important;
}

.priority-low-color {
  color: $tpp-inner-border-default !important;
}

// AG GRID:
.ag-menu.ag-tabs {
  min-width: 180px !important;
}

.ag-header-cell,
.ag-header-group-cell {
  padding: 0 !important;
}
