@import 'variables';

.collections-properties-toggle-container {
  height: 32px !important;
  width: 72px !important;
  padding: 2px !important;
}

.mat-button-toggle-group {
  height: 28px;
  padding: 2px 3px;
  background: $tpp-gray2;
  border-radius: 5px;
  display: flex;
  grid-gap: 2px;
  border: 0 !important;

  .collections-properties-toggle {
    width: 32px !important;
    height: 32px !important;
  }

  .mat-button-toggle {
    width: 28px;
    height: 28px;
    border-radius: 4px;
    background-color: transparent;
    border: 0 !important;

    .mat-button-toggle-button {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .mat-button-toggle-label-content {
        line-height: 0;
        padding: 0;

        .mat-icon {
          height: 18px;
        }
      }
    }

    .mat-icon {
      path {
        fill: $tpp-gray7;
      }
    }

    &.mat-button-toggle-checked {
      background: $tpp-white;

      .mat-icon {
        path {
          fill: $tpp-gray11;
        }
      }
    }
  }
}
