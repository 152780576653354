.cdk-overlay-pane {
  & > .mat-menu-panel {
    max-width: 333px;

    &.notification-list {
      max-width: 435px;
      min-width: 435px;
    }

    &.select-tree {
      max-width: 506px;
      min-width: 506px;
    }
  }
}

@mixin context-menu-with-icon() {
  & > label[mat-menu-item] {
    cursor: auto;
    font-weight: 700 !important;
    pointer-events: none;
  }

  & > .bold {
    font-weight: 700;
  }

  & > button[mat-menu-item],
  & > div[mat-menu-item] {
    app-icon {
      ::ng-deep {
        mat-icon {
          margin-right: 0;
        }
      }
    }
  }
}
