// Sizes
$menu-with-backdrop-min-width: 170px;
$menu-with-backdrop-width: fit-content;

// Color Palette
$tpp-blue-dark: rgb(22 42 87);
$tpp-blue-med: rgb(66 126 255);
$tpp-blue-med-light: rgb(88 168 255);
$tpp-blue-grey-med-dark: #607d8b;
$tpp-blue-gray-light: #eceff1;
$tpp-blue2: #9d9dff;
$tpp-blue3: #4b8fe1;
$tpp-blue4: #006aea;
$tpp-blue5: #98c1d9;
$tpp-blue6: #4fbeff;
$tpp-blue7: #e4eff3;
$tpp-blue8: #dbefff;
$tpp-turquoise: #106580;
$tpp-purple: #e1e1fc;
$tpp-purple2: #6969ee;
$tpp-purple3: #bfbffb;
$tpp-purple4: #ededfd;
$tpp-black: #000;
$tpp-black-shadow: #000a;
$tpp-gray-dark: rgb(50 60 68);
$tpp-gray-med-dark: rgb(120 120 119);
$tpp-gray-med: rgb(180 180 179);
$tpp-gray-med-light: rgb(220 220 219);
$tpp-gray-light: rgb(238 238 238 / 50%);
$tpp-gray-medium-03: rgb(183 183 183 / 30%);
$tpp-gray2: #eee;
$tpp-gray3: #484848;
$tpp-gray4: #999;
$tpp-gray5: #e5e5e5;
$tpp-gray6: #fafafa;
$tpp-gray7: #b9b8b8;
$tpp-gray8: #e1e1e1;
$tpp-gray9: #e9e9e9;
$tpp-gray10: #f0f0f0;
$tpp-gray11: #767575;
$tpp-gray12: rgb(0 0 0 / 5%);
$tpp-gray13: #f3efef;
$tpp-gray14: #dbdad9;
$tpp-gray15: #b7b7b7;
$tpp-gray16: #939391;
$tpp-gray17: #8f8f8f;
$tpp-gray18: #ddd;
$tpp-gray19: #444;
$tpp-gray20: #f5f5f5;
$tpp-green-med: #4caf50;
$tpp-green-dark: #8c9926;
$tpp-green-dark: #8c9926;
$tpp-green2: #108060;
$tpp-green3: #49a986;
$tpp-green4: #129872;
$tpp-green5: #0b6b50;
$tpp-green6: #0f593f;
$tpp-green7: #e1f9f0;
$tpp-green8: #6fedc0;
$tpp-green9: #108664;
$tpp-green10: #0a4736;
$tpp-green11: #dcefe8;
$tpp-green12: #b5dcce;
$tpp-green13: #049b4b;
$tpp-green15: #eaf5f1;
$tpp-green16: #e2f0ea;
$tpp-green-board: #c3dfd2;
$tpp-primary-light: #4eb08d;
$tpp-primary-dark: #005336;
$tpp-table-summary-row: rgb(78 176 141 / 10%);
$tpp-menu-active-button: rgb(78 176 141 / 40%);
$tpp-gold: rgb(160 145 95);
$tpp-red-dark: rgb(165 0 33);
$tpp-red2: #d0021b;
$tpp-red3: #ed1c24;
$tpp-red4: #e93a4f;
$tpp-red5: #fbd8dc;
$tpp-red6: #d74a54;
$tpp-red7: #da2238;
$tpp-red8: #d7263d;
$tpp-red-9: #fbe2e5;
$tpp-red-med: rgb(209 133 135);
$tpp-red-light: rgb(250 230 230);
$tpp-white: #fff;
$tpp-white-07: rgb(255 255 255 / 70%);
$tpp-white-04: rgb(255 255 255 / 40%);
$tpp-dark: #212121;
$tpp-gray-medium: #8a8a8a;
$tpp-box-shadow-color: rgb(72 72 72 / 12%);
$tpp-box-shadow-color2: rgb(0 0 0 / 5%);
$tpp-tooltip-background: rgb(97 97 97 / 90%);
$tpp-inner-border-default: #dadada;
$tpp-inner-border-active: #bdbdbd;
$tpp-input-background: #edf8f4;
$tpp-input-border-focus: #4eb08d;
$tpp-input-border-error: #ff6c6c;
$tpp-input-border-warning: #ffc700;
$tpp-input-border-warning-hover: #f7a80c;
$tpp-input-border-accent: #9ebd59;
$tpp-button-hover-default: #dadada;
$tpp-gray: #484848;
$tpp-blue: #3058c2;
$tpp-pink: #9055c3;
$tpp-pink2: #c2b6ff;
$tpp-pink3: #fc61a2;
$tpp-pink4: #ffbcd9;
$tpp-red: #ff217e;
$tpp-red2: #da2238;
$tpp-red9: #ff6b35;
$tpp-violet: #b08fcc;
$tpp-violet2: #6969ee;
$tpp-violet3: #8025cf;
$tpp-orange: #ff8a00;
$tpp-orange2: #ffba69;
$tpp-orange3: #f09036;
$tpp-orange4: #ff6b35;
$tpp-orange5: #ea912a;
$tpp-orange6: #fff1d7;
$tpp-orange7: #fff7e7;
$tpp-orange8: #ffe2d8;
$tpp-green: #9ebd59;
$tpp-yellow: #ffcd1b;
$tpp-yellow2: #f8cb2c;
$tpp-yellow3: #ffdc5f;
$tpp-yellow4: #fcdb03;
$tpp-yellow5: #fae9b4;
$tpp-violet: #e1e1fc;
$tpp-brown: #a47278;
$tpp-brown2: #a43644;
$tpp-logout-info: rgb(217 0 0 / 68%);
$tpp-row-priority-high: rgb(255 108 108 / 30%);
$tpp-row-priority-medium: rgb(255 199 0 / 30%);
$tpp-pill-tags-background-blue: rgb(48 88 194 / 15%);
$tpp-pill-tags-color-blue: #618cff;
$tpp-pill-tags-background-orange: rgb(255 138 0 / 15%);
$tpp-pill-tags-color-orange: #ff8a00;
$tpp-pill-tags-background-green: rgb(158 189 89 / 15%);
$tpp-pill-tags-color-green: #9ebd59;
$tpp-pill-tags-background-pink: rgb(255 33 126 / 15%);
$tpp-pill-tags-color-pink: #ff217e;
$tpp-pill-tags-background-gray: rgb(255 33 126 / 15%);
$tpp-pill-tags-color-gray: #ff217e;
$tpp-working: #a9b8cf;
$tpp-success: #afbf30;
$tpp-failure: $tpp-red-dark;
$tpp-calendar-selected-range: rgb(78 176 141 / 50%);
$tpp-button-shadow: rgb(78 176 141 / 30%);
$background-light: #f5f7fa; // #F5F5F2;

$threshold-low: $tpp-gray16;
$threshold-medium: $tpp-orange3;
$threshold-high: $tpp-red6;
$avatar-theme-1: $tpp-green;
$avatar-theme-2: $tpp-primary-light;
$avatar-theme-3: $tpp-green13;
$avatar-theme-4: $tpp-turquoise;
$avatar-theme-5: $tpp-violet;
$avatar-theme-6: $tpp-violet3;
$avatar-theme-7: $tpp-blue2;
$avatar-theme-8: $tpp-violet2;
$avatar-theme-9: $tpp-blue3;
$avatar-theme-10: $tpp-blue4;
$avatar-theme-11: $tpp-pink3;
$avatar-theme-12: $tpp-red;
$avatar-theme-13: $tpp-orange2;
$avatar-theme-14: $tpp-orange4;
$avatar-theme-15: $tpp-brown;
$avatar-theme-16: $tpp-brown2;

// Generate palettes here:
// http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5&mcgpalette1=%23b5993f

$mat-myapp-primary: (
  50: #e3eaeb,
  100: #b8cace,
  200: #89a7ae,
  300: #5a848d,
  400: #366974,
  500: #134f5c,
  600: #114854,
  700: #0e3f4a,
  800: #0b3641,
  900: #062630,
  A100: #6ad5ff,
  A200: #37c6ff,
  A400: #04b8ff,
  A700: #00a7e9,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$mat-myapp-blue-gray: (
  50: #f2f4f8,
  100: #dee5ee,
  200: #c8d3e3,
  300: #b2c1d8,
  400: #a2b4cf,
  500: #91a7c7,
  600: #899fc1,
  700: #7e96ba,
  800: #748cb3,
  900: #627ca6,
  A100: #fff,
  A200: #e3edff,
  A400: #b0cdff,
  A700: #96bdff,
  A800: #a0b5da,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$mat-myapp-yellow: (
  50: #fffaed,
  100: #fff4d1,
  200: #ffecb3,
  300: #ffe494,
  400: #ffdf7d,
  500: #ffd966,
  600: #ffd55e,
  700: #ffcf53,
  800: #ffca49,
  900: #ffc038,
  A100: #fff,
  A200: #fff,
  A400: #fff5e1,
  A700: #ffedc8,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #777,
    600: #777,
    700: #777,
    800: #555,
    900: #555,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$mat-myapp-warn: (
  50: #fbeded,
  100: #f6d1d1,
  200: #f0b3b3,
  300: #e99494,
  400: #e57d7d,
  500: #e06666,
  600: #dc5e5e,
  700: #d85353,
  800: #d34949,
  900: #cb3838,
  A100: #fff,
  A200: #ffe2e2,
  A400: #ffafaf,
  A700: #ff9696,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$mat-myapp-gold: (
  50: #f6f3e8,
  100: #e9e0c5,
  200: #dacc9f,
  300: #cbb879,
  400: #c0a85c,
  500: #b5993f,
  600: #ae9139,
  700: #a58631,
  800: #9d7c29,
  900: #8d6b1b,
  A100: #ffecc6,
  A200: #ffdc93,
  A400: #ffcb60,
  A700: #ffc347,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$mat-myapp-accent: (
  50: #faf6ed,
  100: #f4ead2,
  200: #ecdcb5,
  300: #e4cd97,
  400: #dfc380,
  500: #d9b86a,
  600: #d5b162,
  700: #cfa857,
  800: #caa04d,
  900: #c0913c,
  A100: #fff,
  A200: #fff2db,
  A400: #ffdfa8,
  A700: #ffd58f,
  A800: #ffe6ad,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #000,
    900: #000,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$mat-myapp-green: (
  50: #f1f3e5,
  100: #dde0be,
  200: #c6cc93,
  300: #afb867,
  400: #9da847,
  500: #8c9926,
  600: #849122,
  700: #79861c,
  800: #6f7c17,
  900: #5c6b0d,
  A100: #eeff9e,
  A200: #e5ff6b,
  A400: #dcff38,
  A500: #d8dc9c,
  A700: #d8ff1f,
  A800: #d1e6d3,
  A900: #a7ad50,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$mat-myapp-bluegray: (
  50: #eaedf3,
  100: #cbd2e0,
  200: #a8b4cb,
  300: #8596b6,
  400: #6b80a7,
  500: #516997,
  600: #4a618f,
  700: #405684,
  800: #374c7a,
  900: #273b69,
  A100: #adc4ff,
  A200: #7aa0ff,
  A400: #477bff,
  A700: #2e69ff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #fff,
    A700: #fff,
  ),
);

// Possible colors to choose from
$one: #957dff #727ce8 #727ce8 #70a2ff #6cbff5 #122552 #334661 #516997 // Material blueGrey
  #607d8b #b5993f #695310 #7388ff #1a2669 #7e88c1 #0487d9 #65a603 #c6d936 #f27405 #f2f2f2 #f9fbe7 #8c9926 #515916
  #c6d936 #d1e639 #afbf30 #66150f #f23224 #a62219 #b3251b #8c1d15 #b34b12 #bd3713 #a62219 #bd1340 #f5f7fa; // light gray-blue
