@import 'variables';

@mixin modalContainer() {
  z-index: 1;

  .modal-header {
    border-bottom: 2px solid $tpp-gray2;
    padding: 22px 33px 8px;
    margin-bottom: 20px;

    .subtitle {
      word-break: break-word;
    }
  }

  .mat-dialog-content {
    margin: 0;
    padding: 0 33px;
    overflow: auto;
  }

  .mat-dialog-container {
    padding: 0;
  }

  .mat-dialog-actions {
    padding: 20px 33px 0;
    margin-bottom: 20px;
  }
}

.schedule-modal-container {
  > mat-dialog-container {
    overflow: visible;
  }

  &.compact-mode {
    > mat-dialog-container {
      padding: 16px;
    }

    .mat-dialog-title {
      margin: 0;
    }
  }
}

.custom-inspection-modal-container > mat-dialog-container {
  padding: 0;
}

.template-editor-modal-container > mat-dialog-container {
  padding: 0;
}

/* overrides for dnd behavior,
it should in global styles as dnd elements is added to root of thml doc */
.cdk-drag-handle {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.dnd-with-expandable {
  &.cdk-drag-preview,
  &.cdk-drag-dragging,
  &.cdk-drag-placeholder {
    height: fit-content !important;

    .expandable {
      grid-template-rows: 0fr;
    }
  }
}

.upload-price-list-dialog > mat-dialog-container {
  padding: 0;
}
