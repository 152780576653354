button.centered-icon .mat-button-wrapper {
  display: flex;
  align-items: center;
  grid-column-gap: 5px;

  mat-icon {
    display: flex;
    align-items: center;
  }
}
