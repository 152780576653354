$widths-config: (
  10: 10,
  20: 20,
  40: 40,
  50: 50,
  70: 70,
  80: 80,
  90: 90,
  100: 100,
  105: 105,
  110: 110,
  120: 120,
  130: 130,
  150: 150,
  170: 170,
  200: 200,
  210: 210,
  220: 220,
  230: 230,
  240: 240,
  250: 250,
  260: 260,
  270: 270,
  280: 280,
  290: 290,
  300: 300,
  310: 310,
  320: 320,
  330: 330,
  340: 340,
  350: 350,
);

@mixin element-width($value) {
  width: #{$value}px !important;
}

@each $key, $val in $widths-config {
  .width-#{$key} {
    @include element-width($key);
  }
}

.w-auto {
  width: auto !important;
}

.w-10 {
  width: 10%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100% !important;
}

.wmin-360 {
  min-width: 360px;
}

.mw-100 {
  max-width: 100%;
}

.wp-18 {
  width: 18px !important;
}

.wp-15 {
  width: 15px !important;
}

.wp-95 {
  width: 95px;
}

.wp-40 {
  width: 40px !important;
}

.wp-80 {
  width: 80px !important;
}

.wp-100 {
  width: 100px !important;
}

.wp-112 {
  width: 112px !important;
}

.wp-160 {
  width: 160px !important;
}

.wp-200 {
  width: 200px !important;
}

.wp-220 {
  width: 220px !important;
}

.h-50 {
  height: 50%;
}

.h-100 {
  height: 100%;
}

.hp-80 {
  height: 80px !important;
}

.hp-92 {
  height: 92px !important;
}

.hp-38 {
  height: 38px !important;
}

.hp-32 {
  height: 32px !important;
}

.hp-70 {
  height: 70px !important;
}

.hp-50 {
  height: 50px !important;
}

.hp-18 {
  height: 18px !important;
}

.hp-15 {
  height: 15px !important;
}

.h-auto {
  height: auto;
}

.min-h-50 {
  min-height: 50px;
}

.aspect-square {
  aspect-ratio: 1/1;
}
