@import 'variables';

.ng-dropdown-panel {
  min-width: 150px !important;

  .ng-dropdown-panel-items {
    .ng-option {
      padding: 8px;
    }
  }

  .ng-dropdown-footer {
    text-align: center;

    a {
      font-size: 14px;
    }
  }
}

/* complex styles for dropdowns */
app-radio-button-dropdown {
  &.slim-control .ng-select .ng-select-container {
    height: initial;
    min-height: 27px;

    & > .ng-value-container {
      padding: 4px 0 4px 4px !important;
    }
  }

  &.capsule {
    .radio-button-dropdown-content {
      border: none;
      height: 26px;
    }

    .ng-select-container {
      background-color: $tpp-primary-light;
      border-radius: 12px;
      max-height: 26px;
      min-height: 26px;

      .ng-value-container,
      .ng-arrow-wrapper {
        padding-top: 1px !important;
      }
    }

    .dropdown-value-style:not(.text-color) {
      color: $tpp-white;
      font-size: 14px;
      font-weight: 700;
    }

    .dropdown-value-style:not(.pre-title, .pre-label, .pre-text, .body-small-bold, .inside) {
      font-size: 14px;
      font-weight: 700;
    }

    .ng-select {
      &.ng-select-opened {
        & > .ng-select-container {
          background-color: $tpp-primary-light;

          .ng-arrow {
            top: -2px;
            border-color: transparent transparent #fff;
            border-width: 5px;
          }
        }
      }

      .ng-arrow-wrapper .ng-arrow {
        border-color: #fff transparent transparent;
      }
    }
  }

  &.scoring-capsule {
    width: 128px;
    border-radius: 12px;

    .radio-button-dropdown-content {
      border-radius: 12px;
      border: none;
      height: 26px;
    }

    .ng-select-container {
      width: 128px;
      background-color: $tpp-primary-light;
      border-radius: 12px;
      max-height: 26px;
      min-height: 26px;

      .ng-value-container,
      .ng-arrow-wrapper {
        padding-top: 1px !important;
      }
    }

    .dropdown-value-style:not(.text-color) {
      font-size: 14px;
      font-weight: 700;
    }

    .dropdown-value-style:not(.pre-title, .pre-label, .pre-text, .body-small-bold, .inside) {
      font-size: 12px;
      color: #fff;
    }

    .ng-select {
      &.ng-select-opened {
        & > .ng-select-container {
          border-radius: 12px;
          background-color: $tpp-primary-light;

          .ng-arrow {
            top: -2px;
            border-color: transparent transparent #fff;
            border-width: 5px;
          }
        }
      }

      .ng-arrow-wrapper .ng-arrow {
        border-color: #fff transparent transparent;
      }
    }
  }

  &.full-capsule {
    .radio-button-dropdown-container,
    .radio-button-dropdown-content {
      border-radius: 12px !important;
      border: none;
      height: 26px;
    }

    .ng-select-container {
      border-radius: 12px !important;
      max-height: 26px;
      min-height: 26px;
    }

    .ng-select {
      .ng-arrow {
        top: 1.25px;
      }
    }
  }

  &.status {
    .dropdown-value-style:not(.pre-title, .pre-label, .pre-text, .body-small-bold, .inside) {
      font-size: 14px;
      font-weight: 400;
    }
  }

  &.status.resolved {
    .ng-select-container {
      background: linear-gradient(0deg, #dcefe8, #dcefe8), $tpp-white;
    }

    .dropdown-value-style:not(.text-color) {
      color: $tpp-primary-light;
    }

    .ng-select {
      &.ng-select-opened {
        & > .ng-select-container {
          background: linear-gradient(0deg, #dcefe8, #dcefe8), $tpp-white;

          .ng-arrow {
            border-color: transparent transparent $tpp-primary-light;
          }
        }
      }

      .ng-arrow-wrapper .ng-arrow {
        border-color: $tpp-primary-light transparent transparent;
      }
    }
  }

  &.status.canceled,
  &.status.declined {
    .ng-select-container {
      background: #e7d7c5;
    }

    .dropdown-value-style:not(.text-color) {
      color: red;
    }

    .ng-select {
      &.ng-select-opened {
        & > .ng-select-container {
          background: #e7d7c5;

          .ng-arrow {
            border-color: transparent transparent red;
          }
        }
      }

      .ng-arrow-wrapper .ng-arrow {
        border-color: red transparent transparent;
      }
    }
  }

  &.status.in-progress,
  &.status.pending,
  &.status.need-parts {
    .ng-select-container {
      background: linear-gradient(0deg, rgb(234 145 42 / 20%), rgb(234 145 42 / 20%)), $tpp-white;
    }

    .dropdown-value-style:not(.text-color) {
      color: $tpp-orange5;
    }

    .ng-select {
      &.ng-select-opened {
        & > .ng-select-container {
          background: linear-gradient(0deg, rgb(234 145 42 / 20%), rgb(234 145 42 / 20%)), $tpp-white;

          .ng-arrow {
            border-color: transparent transparent $tpp-orange5;
          }
        }
      }

      .ng-arrow-wrapper .ng-arrow {
        border-color: $tpp-orange5 transparent transparent;
      }
    }
  }

  &.status.reopened {
    .ng-select-container {
      background: $tpp-gray8;
    }

    .dropdown-value-style:not(.text-color) {
      color: $tpp-gray;
    }

    .ng-select {
      &.ng-select-opened {
        & > .ng-select-container {
          background: $tpp-gray8;

          .ng-arrow {
            border-color: transparent transparent $tpp-gray4;
          }
        }
      }

      .ng-arrow-wrapper .ng-arrow {
        border-color: $tpp-gray4 transparent transparent;
      }
    }
  }

  &.status.new {
    .ng-select-container {
      background: #fff;
      border: 1px solid $tpp-inner-border-default !important;
    }

    .dropdown-value-style:not(.text-color) {
      color: $tpp-gray;
    }

    .ng-select {
      &.ng-select-opened {
        & > .ng-select-container {
          border-bottom-right-radius: 12px;
          border-bottom-left-radius: 12px;
          background: #fff;

          .ng-arrow {
            border-color: transparent transparent $tpp-gray4;
          }
        }
      }

      .ng-arrow-wrapper .ng-arrow {
        border-color: $tpp-gray4 transparent transparent;
      }
    }
  }
}

@mixin dropdownContainer() {
  .label-container {
    align-items: center !important;
    display: flex !important;
    grid-gap: 4px;
  }

  label {
    @include customLabel;

    &.inside {
      padding: 0 6px 0 0;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }
  }
}

/* custom chevron implementations */
.chevron {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  position: absolute;
  top: 15.5px;
  right: 14px;
  cursor: pointer;
}

.chevron:hover {
  border-color: #666 transparent transparent;
}

.chevron.expanded {
  border-width: 0 5px 5px;
  border-color: transparent transparent #999;
}

.chevron.expanded:hover {
  border-color: transparent transparent #666;
}
