$gaps-config: (
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  8: 8,
  10: 10,
  11: 11,
  12: 12,
  14: 14,
  16: 16,
  18: 18,
  20: 20,
  24: 24,
  32: 32,
  48: 48,
  54: 54,
  55: 55,
  64: 64,
  80: 80,
  120: 120,
  128: 128,
);

@mixin grid-gap($value) {
  grid-gap: #{$value}px;
}

@each $key, $val in $gaps-config {
  .grid-gap-#{$key} {
    @include grid-gap($key);
  }
}

.flex-spacer {
  flex-grow: 1;
}

.flex-grow-1 {
  flex-grow: 1;
}
