@import 'variables';

.ng-select {
  font-size: 14px;
}

.ng-select[color='primary'] .ng-select-container {
  border: 1px solid $tpp-primary-light;
}

.ng-select .ng-select-container {
  border: none;
  color: $tpp-dark;

  &:hover {
    box-shadow: none;
  }

  .ng-input {
    padding-left: 5px !important;
    cursor: pointer;

    input {
      height: 36px;
      font-size: 12px;
      top: 10px;
      cursor: text !important;
    }
  }
}

.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.ng-dropdown-panel {
  border-radius: 5px;
  box-shadow: 4px 4px 4px $tpp-box-shadow-color;

  .ng-dropdown-panel-items {
    .ng-option {
      &.ng-option-selected {
        background: none;
      }

      &.ng-option-marked {
        background-color: $tpp-table-summary-row !important;
      }
    }
  }
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: none;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  position: unset !important;
  padding: 0;
  color: $tpp-gray11;

  @include customPlaceholder;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: $tpp-white;
  border-radius: 6px;
  font-size: 14px;
  color: $tpp-dark;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 288px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: $tpp-table-summary-row;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: $tpp-white;
}
