@import 'variables';

.mat-chip-list .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 1;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: $tpp-green11 !important;
  color: $tpp-input-border-focus !important;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%) inset, 0 1px 2px 0 #fff;

  mat-icon {
    svg path {
      fill: $tpp-input-border-focus;
    }
  }
}
