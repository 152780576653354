@use '@angular/material' as mat;
@import 'variables';
@import '@angular/material/theming';
@import '@ng-select/ng-select/themes/default.theme.css';
@import '@circlon/angular-tree-component/css/angular-tree-component.css';
@import 'viewerjs/dist/viewer.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-quartz.css';
@import 'swiper/swiper-bundle.css';
@import 'app/sass/common';
@import 'app/sass/toggle';
@import 'app/sass/buttons';
@import 'app/sass/tables';
@import 'app/sass/tabs';
@import 'app/sass/inputs';
@import 'app/sass/selects';
@import 'app/sass/flex';
@import 'app/sass/fonts';
@import 'app/sass/position';
@import 'app/sass/text';
@import 'app/sass/progress';
@import 'app/sass/size';
@import 'app/sass/menu';
@import 'app/sass/container';
@import 'app/sass/dropdowns';
@import 'app/sass/chips';
@import 'app/sass/modals';
@import 'app/sass/breakpoints';
@import 'app/sass/dialogs';
@import 'app/sass/tooltips';
@import 'app/sass/calendar';
@import 'assets/material/button';
@import 'app/sass/mat-spinner';
@include mat.core;

$primary: mat.define-palette($mat-myapp-primary, 400);
$accent: mat.define-palette($mat-myapp-accent, 500);
$warn: mat.define-palette($mat-myapp-warn, 500);
$theme: mat.define-light-theme($primary, $accent, $warn);
$custom-typography: mat.define-typography-config(
  $font-family: 'Lato',
);

@include mat.core($custom-typography);
@include angular-material-theme($theme);

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -moz-tap-highlight-color: transparent;
  -ms-tap-highlight-color: transparent;
  user-select: none;
}

:host {
  @include systemFont;

  font-size: 14px;
  color: #333;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;

  @include systemFont;
}

.cdk-virtual-scroll-spacer {
  position: absolute;
}

input,
button,
select {
  font-family: inherit;
  font-size: inherit;
}

p {
  @include systemFont;

  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 1.1em;
}

.remark {
  margin-top: 16px;
  font-style: italic;
  font-size: 12px;
  font-weight: 300;

  b {
    font-weight: 700;
  }
}

a {
  text-decoration: none;
  color: $tpp-green2;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.button-text {
  background-color: transparent;
  border: none;
  text-decoration: none;
  color: $tpp-green2;
  cursor: pointer;

  &:hover {
    text-decoration: underline solid $tpp-green2;
  }

  &:focus {
    outline: none;
  }
}

.link {
  cursor: pointer;
}

.med-text {
  font-size: 0.9em;
}

.mat-expansion-panel {
  // border: 1px solid map-get($map: $mat-myapp-primary, $key: 100);
  @include systemFont;
}

.mat-checkbox {
  @include systemFont;

  font-weight: 300;
  cursor: pointer;

  &.mat-checkbox-checked {
    &.mat-primary {
      .mat-checkbox-background {
        background-color: $tpp-primary-light;
      }
    }
  }

  &.mat-checkbox-disabled {
    cursor: not-allowed;

    &.mat-primary {
      .mat-checkbox-background {
        background-color: $tpp-gray4;
      }
    }
  }

  .mat-checkbox-label {
    font-weight: 400;
    font-size: 14px;
  }

  &.mat-checkbox-small {
    .mat-checkbox-label {
      display: flex;
      font-size: 12px;
    }

    .mat-checkbox-inner-container {
      width: 12px;
      height: 12px;
    }

    .mat-checkbox-frame {
      width: 12px;
      height: 12px;
    }

    .mat-checkbox-background {
      width: 12px;
      height: 12px;
    }

    .mat-checkbox-checkmark {
      transform: scale(0.75);
    }
  }

  &.mat-checkbox-small-no-label {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    width: 14.5px;
    height: 14.5px;

    .mat-ripple-element {
      display: none !important;
    }
  }
}

.mat-card {
  @include systemFont;

  background-color: $background-light;
}

.mat-expansion-panel-header {
  @include systemFont;
}

.mat-select {
  @include systemFont;
}

mat-chip {
  display: flex;
  flex-direction: row;
  place-content: center space-between;
  width: 100%;
  max-width: 440px;
  font-size: inherit;

  mat-icon {
    margin-right: 5px;
  }

  .attachment-label-container {
    display: flex;
    flex-direction: column;
    place-content: center flex-start;
  }
}

.mat-icon-button {
  &:hover:not(.mat-icon-button-disabled) .mat-button-focus-overlay {
    opacity: 0.04;
  }
}

.spacer {
  flex: 1 1 auto;
}

div.vertSpacer {
  margin-top: 15px;
}

.text-left {
  text-align: left;
}

.text-centered {
  text-align: center !important;
}

.align-centered {
  display: flex;
  place-content: center center;
  width: 100%;
}

.text-right {
  text-align: right !important;
  justify-content: end;
}

.tpp-tooltip {
  font-size: 90%;
}

.tpp-col-3vw {
  width: 3vw;
}

.tpp-col-5vw {
  width: 5vw;
}

input.tpp-input,
textarea.tpp-input {
  font-size: inherit;
  color: $tpp-blue-med;
  border-radius: 4px;
  border: 1px solid rgb(0 0 0 / 10%);

  // border: 2px solid rgba(88, 168, 255, 0.2);
  padding: 5px;
  background: #eceff1;

  // background: inherit;
  margin: 0 0 0 -5px;
  width: 100%;

  &.short-input {
    width: 100px;
  }
}

mat-panel-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 35px;
  font-size: 1.1em;
  font-weight: 500;

  @include systemFont;
}

.mat-table {
  width: 100%;

  td.numeric,
  th.numeric {
    text-align: right;
  }

  td.sortable.numeric {
    padding-right: 2em;
  }

  th.x-small {
    width: 3%;
  }

  th.small {
    width: 6%;
  }

  th.med {
    width: 12%;
  }

  th.large {
    width: 18%;
  }

  th.x-large {
    width: 24%;
  }

  tr.mat-footer-row,
  tr.mat-row {
    height: 2em;
  }
}

table.concise-table {
  min-width: 100%;
  font-size: 12pt;
  font-weight: 300;
  border-collapse: collapse;

  tr {
    width: 100%;

    // height: 40px;
  }

  td {
    width: auto;
    text-align: left;

    // padding: 5px 10px 5px 0px;
    font-size: 10pt;
    font-weight: 300;
    height: 30px;
    padding: 5px 10px;
  }

  td:nth-child(1) {
    font-weight: 500;
  }

  td.numeric {
    text-align: right;
  }

  th {
    text-align: left;
    font-size: 9pt;
    font-weight: 300;
    border-bottom: 1px solid $tpp-gray-med-light;
    height: 30px;
    padding: 3px 10px;
  }

  tr.tpp-delineated-row {
    border-bottom: 0.25px solid $tpp-gray-med-light;
  }

  .tpp-name-col {
    // font-weight: bold;
    font-weight: 300;
    min-width: 150px;
  }

  div.spacer-row {
    min-height: 5px;
  }
}

.mat-snack-bar-handset snack-bar-container.mat-snack-bar-container {
  width: fit-content;
}

.mat-snack-bar-container {
  min-width: 10px !important;

  &.snackbar-working {
    background-color: $tpp-working;
  }

  &.snackbar-success {
    background-color: #108060;
  }

  &.snackbar-failure {
    background-color: $tpp-failure;
  }
}

.tpp-warning-text {
  color: $tpp-red-dark;
  font-size: 12pt;
  font-style: oblique;
  text-align: center;
}

mat-radio-button {
  margin-right: 15px;
}

.error-message {
  color: $tpp-failure;
}

.flex-container-center-aligned {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-container-row-centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

div.placeholder-avatar {
  height: 200px;
  border: 4px solid lightgray;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  & > mat-icon {
    margin: -75px 0 0 -70px;
    font-size: 72pt;
    color: lightgray;
  }
}

.mat-expansion-panel.filter-panel {
  background-color: map-get($map: $mat-myapp-primary, $key: 200);
  color: $tpp-white;

  .mat-card {
    box-shadow: none;
    padding: 0;
  }
}

mat-card.filter-panel {
  background-color: map-get($map: $mat-myapp-primary, $key: 200);
  color: white;
  font-size: 0.8rem;

  div.facet-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 5px 15px;
    place-items: flex-start flex-start;
    font-size: 100%;
    font-weight: 300;
    margin: 15px;
  }

  .filter-radio-buttons {
    display: flex;
    flex-direction: column;

    mat-radio-button {
      margin: 3px 0;
    }
  }

  h4.filter {
    margin: 0 0 5px;
  }
}

.table-loader {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 200px;
}

div.grid-container {
  display: grid;
  background-color: map-get($map: $mat-myapp-accent, $key: 400);
  color: white;
  grid-template-rows: 40px;
  place-content: center center;
  border-collapse: collapse;

  & > div {
    &.header-row {
      display: flex;
      width: 100%;
      font-size: 0.9em;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &.header-col {
      place-content: start start;
      margin-left: 10px;
    }
  }
}

div.grid-container-2 {
  display: grid;
  border-collapse: collapse;

  // border-bottom: 1px solid $tpp-gray-med-light;
  grid-template-rows: 24px;
  place-items: center center;

  & > div {
    &.header-col {
      width: 100%;
      place-items: start start;
      font-weight: bold;
      margin-left: 15px;
    }

    &.grid-item {
      font-size: 0.9em;
      font-weight: 300;
    }

    &.numeric {
      width: 100%;
      text-align: right;
    }

    &.textContent {
      width: 100%;
      text-align: left;
    }
  }
}

.primary-light-border {
  border: 1px solid $tpp-input-border-focus;
}

*::-webkit-scrollbar {
  appearance: none;
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $tpp-primary-light;
  box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}

.no-scrollbar {
  &::-webkit-scrollbar {
    display: none;
    scrollbar-width: none; /* Firefox */
  }
}

.nowrap {
  white-space: nowrap;
}

tree-node-content {
  font-size: 14px;
}

tree-node-checkbox {
  display: inline-block;
  width: 22px;
  height: 21px;
  position: relative;
  margin: 2px 0 0;
  vertical-align: middle;
}

input[type='checkbox'] {
  outline: none;
  margin: 0;
  appearance: initial;
  position: absolute;
  cursor: pointer;
  border-radius: 2px;

  &::after {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 2C1 1.44772 1.44772 1 2 1H14C14.5523 1 15 1.44772 15 2V14C15 14.5523 14.5523 15 14 15H2C1.44772 15 1 14.5523 1 14V2Z' fill='white' stroke='%23DADADA' stroke-width='2'/%3E%3C/svg%3E%0A");
    position: absolute;
  }

  &:checked::after {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' fill='white'/%3E%3Cpath d='M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2Z' fill='%234EB08D'/%3E%3Cpath d='M11.8254 4.23489C12.0638 3.95435 12.4845 3.92024 12.7651 4.1587C13.0456 4.39715 13.0797 4.81788 12.8413 5.09842L7.17461 11.7651C6.93241 12.05 6.50325 12.08 6.22374 11.8316L3.22374 9.16493C2.94856 8.92032 2.92377 8.49894 3.16838 8.22375C3.41299 7.94856 3.83437 7.92377 4.10956 8.16838L6.60013 10.3822L11.8254 4.23489Z' fill='white'/%3E%3C/svg%3E%0A");
    position: absolute;
  }

  &:indeterminate::after {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' fill='white'/%3E%3Cpath d='M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2Z' fill='%234EB08D'/%3E%3Crect x='3.02148' y='7.25' width='9.95707' height='1.5' rx='0.75' fill='white'/%3E%3C/svg%3E%0A");
    position: absolute;
  }
}

.mat-divider {
  border-top-color: $tpp-gray2;
}

.mat-menu-panel {
  box-shadow: none;
  background: transparent;

  .mat-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.menu-with-backdrop {
    background: $tpp-white;
    border: 1px solid rgb(72 72 72 / 12%);
    box-shadow: 4px 4px 4px rgb(72 72 72 / 12%);
    border-radius: 8px;
    min-width: $menu-with-backdrop-width;
    min-height: 32px;

    .mat-menu-content {
      padding: 4px;

      .mat-menu-item {
        height: 30px;
        line-height: 30px;
        font-weight: 500;
        font-size: 14px !important;
        border-radius: 4px;

        &:focus {
          background-color: $tpp-white;
        }

        &:not([disabled]):hover,
        &.mat-menu-item-highlighted {
          background-color: $tpp-green12;
        }

        &:not([disabled]):active {
          background-color: $tpp-green2;
          color: $tpp-white;
        }

        .info-item {
          display: flex;
          grid-gap: 8px;
          align-items: center;

          .mat-icon {
            color: $tpp-dark;
            margin: 0;
          }
        }
      }

      .mat-divider {
        margin: 8px 16px;
        border-top-color: $tpp-gray2;
      }
    }

    &.context-menu {
      background-color: $tpp-primary-dark;

      .mat-menu-content {
        .mat-menu-item {
          height: 24px;
          line-height: 24px;
          font-size: 10px !important;
          text-transform: uppercase;
          color: $tpp-white;

          &:focus {
            background-color: $tpp-primary-dark;
          }

          &:hover,
          &.mat-menu-item-highlighted {
            background-color: $tpp-green12;
            color: $tpp-dark;
          }

          &:active {
            background-color: $tpp-white;
            color: $tpp-dark;
          }
        }
      }
    }

    &.turnovers-menu {
      padding: 8px;
      border: 0;

      .mat-menu-content {
        .mat-menu-item {
          cursor: pointer;
        }
      }
    }

    &.filter-menu {
      max-width: 500px;
      overflow: hidden;
    }

    &.emoji-menu {
      .emoji-mart-search-icon {
        height: 100%;
        top: 1px;
      }
    }

    .empty-table-container {
      padding: 16px;

      h4 {
        margin-block: 4px;
      }
    }
  }
}

.mat-icon-button.simple-mat-icon-button {
  width: 24px;
  height: 24px;
}

.mat-menu-panel.simple-mat-menu {
  min-height: 32px;
  background-color: white !important;
  border: 1px solid $tpp-box-shadow-color;
  border-radius: 8px;
  box-shadow: 4px 4px 4px $tpp-box-shadow-color;

  &-columns {
    width: 180px;
    max-height: 390px;
    padding: 4px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.mat-checkbox.simple-mat-checkbox {
  width: 180px;
  height: 30px;
  color: $tpp-dark;
  font-size: 12px;
  line-height: 140%;
}

.simple-mat-menu-columns .simple-mat-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.simple-mat-menu-columns .simple-mat-checkbox.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $tpp-primary-light;
}

.spinner-container {
  padding: 16px 0;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-sort-header {
  cursor: pointer;

  &[aria-sort='ascending'] {
    &::after {
      margin-left: 4px;
      content: url("data:image/svg+xml,%3Csvg width='6' height='13' viewBox='0 0 6 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 0L5.59808 4.5H0.401924L3 0Z' fill='%23212121'/%3E%3Cpath d='M3 13L0.401924 8.5H5.59808L3 13Z' fill='%23B9B8B8'/%3E%3C/svg%3E%0A");
    }
  }

  &[aria-sort='descending'] {
    &::after {
      margin-left: 4px;
      content: url("data:image/svg+xml,%3Csvg width='6' height='13' viewBox='0 0 6 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 0L5.59808 4.5H0.401924L3 0Z' fill='%23B9B8B8'/%3E%3Cpath d='M3 13L0.401924 8.5H5.59808L3 13Z' fill='%23212121'/%3E%3C/svg%3E%0A");
    }
  }

  &[aria-sort='none'] {
    &::after {
      margin-left: 4px;
      content: url("data:image/svg+xml,%3Csvg width='6' height='13' viewBox='0 0 6 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 0L5.59808 4.5H0.401924L3 0Z' fill='%23B9B8B8'/%3E%3Cpath d='M3 13L0.401924 8.5H5.59808L3 13Z' fill='%23B9B8B8'/%3E%3C/svg%3E");
    }
  }
}

.label-required {
  &::after {
    font-size: 12px;
    content: ' *';
    color: $tpp-red2;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.text-bold {
  font-weight: 700;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $tpp-tooltip-background;
}

.screen-wiper {
  height: 100%;
  position: absolute;
  width: 3px;
  top: 0;
  z-index: 9999;
  left: 0%;
  display: none;

  &.state-0 {
    background-color: #f00;
  }

  &.state-1 {
    background-color: #0f0;
  }

  &.state-2 {
    background-color: #00f;
  }

  &.start {
    transition: left var(--wipe-duration);
    left: 0%;
    display: block;
  }

  &.end {
    transition: left var(--wipe-duration);
    left: calc(100% - 3px);
    display: block;
  }
}

app-mention-list {
  top: 10px !important;
}

.appointment-container {
  .mat-dialog-container {
    padding: 0;
    overflow: visible;
    box-shadow: 0 0 10px 2px #0000000d;
  }
}

.table-loader-new {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  min-height: 20px;
  height: 20px;
  background: linear-gradient(90deg, #eee 25%, #eaeaea 50%, #eee 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  transition: height 0.3s ease;
  border-radius: 6px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: repeating-linear-gradient(to bottom, transparent 0, transparent 38px, white 38px, white 40px);
    pointer-events: none;
    border-radius: 6px;
  }
}

.mat-checkbox.highlight-checkbox.mat-checkbox-checked .mat-checkbox-frame,
.mat-checkbox.highlight-checkbox .mat-checkbox-frame {
  border-color: $tpp-input-border-error;
}

.mat-checkbox.highlight-checkbox .mat-checkbox-inner-container {
  border-color: $tpp-input-border-error !important;
}

.skeleton-loader {
  background: linear-gradient(90deg, $tpp-gray2 25%, $tpp-gray5 50%, $tpp-gray2 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 5px;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}
