@import 'variables';

.header {
  .title {
    &.expanded {
      height: 40px;
    }

    .title-inner {
      display: flex;
      grid-gap: 16px;
      align-items: center;
      width: 100%;
      font-weight: 700;
      font-size: 12px;
      line-height: 140%;
      text-transform: uppercase;
    }
  }
}

.border-box {
  box-sizing: border-box;
}

@mixin sectionContainer() {
  background-color: $tpp-white;
  border-radius: 10px;
  box-shadow: 0 0 10px 2px $tpp-box-shadow-color2;
  border: 1px solid $tpp-box-shadow-color2;

  .section-content {
    margin: 20px 19px 15px;
    padding: 4px;

    &.narrow {
      margin: 5px;
    }

    &.settings-sections {
      margin: 16px 20px;
      padding: 0;
    }

    .empty {
      border: 1px solid $tpp-input-border-error !important;
      border-radius: 6px;
    }
  }

  .error-section {
    margin: 0 8px 8px;
  }
}

@mixin refreshedSectionContainer() {
  background-color: $tpp-white;
  border-radius: 10px;
  box-shadow: 0 0 10px 2px $tpp-box-shadow-color2;
  border: 1px solid $tpp-box-shadow-color2;

  .section-content {
    margin: 16px 19px 15px 0;

    .pl-17 {
      padding-left: 17px;
    }

    .pr-17 {
      padding-right: 17px;
    }
  }
}

@mixin viewContent() {
  .header-container {
    padding-bottom: 8px;
  }

  .form-container {
    margin: 0;
  }

  .footer-container {
    padding-top: 4px;
  }
}

@mixin pageGradient() {
  margin: 0;
  background: linear-gradient(162.46deg, $tpp-green8 0.34%, $tpp-green9 50.87%, $tpp-green10 99.48%),
    linear-gradient(0deg, $tpp-gray6, $tpp-gray6);
  background-repeat: no-repeat;
  background-attachment: fixed;
}
