@use '@angular/material' as mat;
@import 'fonts.mixins';
@import 'variables';

@mixin editedCell() {
  &.edited-cell {
    height: 66px;
  }
}

@mixin tableCell() {
  vertical-align: baseline;
  white-space: nowrap;
  padding: 0 4px;

  &.mat-header-cell {
    padding: 8px 4px;
  }
}

@mixin columnWidth($name, $value) {
  .mat-column-#{$name} {
    width: #{$value};
  }
}

@mixin columnWidthMinMax($name, $minValue, $maxValue) {
  .mat-column-#{$name} {
    min-width: #{$minValue};
    max-width: #{$maxValue};
  }
}

@mixin tableCells() {
  border-bottom: none;
  padding: 4px 6px;

  &:last-of-type {
    padding-right: 12px;
  }

  &:first-of-type {
    padding-left: 12px;
  }

  &.text-centered {
    text-align: center;
  }

  &.no-padding {
    padding: 0;
  }
}

@mixin tableContainer() {
  border-radius: 5px;
  background: $tpp-white;
  box-shadow: 4px 4px 6px $tpp-box-shadow-color;
  padding: 0 16px 16px 0;
  overflow-x: auto;

  &.sticky-header {
    th {
      position: sticky;
      top: 0;
      background-color: $tpp-white;
      z-index: 1;
    }
  }

  &.no-shadow {
    box-shadow: none;
  }

  .mat-header-row {
    &.header-small {
      height: 30px;
    }
  }

  ::ng-deep {
    th {
      color: $tpp-dark;

      &.mat-header-cell {
        @include tableCells;

        .mat-sort-header-container {
          .mat-sort-header-content {
            display: block;
          }
        }
      }

      &.text-centered {
        .mat-sort-header-container {
          justify-content: center;

          .mat-sort-header-content {
            display: block;
          }
        }
      }

      &.left-separator {
        border-left: 1px solid $tpp-input-border-focus;
      }
    }

    td {
      &.mat-footer-cell {
        vertical-align: middle;

        @include tableCells;
      }

      &.mat-cell {
        vertical-align: middle;

        @include tableCells;

        &.toggle-section-column {
          padding-top: 0;
          vertical-align: top;
          padding-right: 0;
          width: 30px;

          .button-icon-toggle {
            margin-top: 4px;
          }

          .section-path-container {
            height: 50px;

            .section-path {
              -moz-border-radius-bottomleft: 5px;
              border-bottom-left-radius: 5px;
              border-left: 1px solid $tpp-green2;
              border-bottom: 1px solid $tpp-green2;
              width: 15px;
              margin-left: 15px;
              height: 36px;
            }
          }
        }

        &.sub-table-section-column {
          padding-left: 0;
        }
      }

      &.mat-footer-cell {
        border-bottom: none;
        font-weight: 700;
      }

      &.left-separator {
        border-left: 1px solid $tpp-input-border-focus;
      }
    }
  }

  .mat-footer-row {
    background-color: $tpp-table-summary-row;
  }

  &:not(.no-hover) {
    .select-row {
      &:hover {
        background-color: $tpp-table-summary-row !important;
        cursor: pointer;
      }
    }
  }

  .highlight-row {
    background: $tpp-table-summary-row;
    box-shadow: inset 1px 1px 8px 4px $tpp-menu-active-button;
  }

  .new-item {
    td {
      border-bottom: 2px solid $tpp-menu-active-button !important;
    }
  }

  .rejected-item {
    background: repeating-linear-gradient(180deg, $tpp-red3 0%, $tpp-red3 100%);
    background-size: 100% 2px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .mat-table-sticky-border-elem-top {
    top: -4px !important;
  }
}
